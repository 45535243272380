import { useSelector } from "react-redux";
// metadata
import { Helmet, HelmetProvider } from "react-helmet-async";

interface AppHelmetTypes {
  isDefaultDomain: boolean;
}

export default function AppHelmet({ isDefaultDomain }: AppHelmetTypes) {
  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);

  return (
    <HelmetProvider>
      <Helmet>
        {/* TBD: This should be done with Translation user stories, to be dynamically according to language selected and should be handled on server side (remove this if is unnecessary)  */}
        <html lang="en" /> {/* Set the language of your content */}
        <title>{isDefaultDomain ? "Get Local" : hotelInfo?.name}</title>
        <meta
          name="description"
          content={
            isDefaultDomain ? `Get Local : The key to the city` : `${hotelInfo?.name} : ${hotelInfo?.address?.city}`
          }
        />
        {/* Open Graph tags */}
        <meta property="og:title" content={isDefaultDomain ? "Get Local" : hotelInfo?.name} />
        <meta
          property="og:description"
          content={
            isDefaultDomain ? `Get Local : The key to the city` : `${hotelInfo?.name} : ${hotelInfo?.address?.city}`
          }
        />
        {/* TBD: This should be done with Translation user stories, to be dynamically according to language selected and should be handled on server side (remove from this if is unnecessary)   */}
        <meta property="og:locale" content="en_GB" /> {/* Set the Open Graph locale */}
      </Helmet>
    </HelmetProvider>
  );
}
