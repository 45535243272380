import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export default function CustomFormDateAndTimeBox(props: any) {
  const { t } = useTranslation();

  const isAllDayOffer: boolean = props.uiSchema["ui:options"].props?.event_type === 2;

  return (
    <Box>
      <Box className="form-group" m={0}>
        <label className="form-group__label form-group__label--max-width-90" htmlFor={props.id}>
          {t("checkout.dateLabel")}
        </label>
        <div className="form-group__text">{props.uiSchema["ui:options"].props?.date}</div>
      </Box>
      {!isAllDayOffer && (
        <Box className="form-group" mt={2}>
          <label className="form-group__label form-group__label--max-width" htmlFor={props.id}>
            {t("checkout.timeLabel")}
          </label>
          <div className="form-group__text">{props.uiSchema["ui:options"].props?.time}</div>
        </Box>
      )}
    </Box>
  );
}
