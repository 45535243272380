import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

interface AppIconBoxProps {
  description?: string;
  id: any;
  imageSource: string | undefined;
  value: any;
  valueLabel?: string;
  name?: string;
}

export default function AppIconBox({ description, id, imageSource, value, valueLabel, name }: AppIconBoxProps) {
  return (
    <Grid item key={id}>
      <Box className="icon">
        <div className="icon__image">
          <img title={name} src={imageSource} alt={name ? name : "icon-img"} />
        </div>
        <div className="icon__price-box">
          <span className="icon__price-box__price">
            {valueLabel ? valueLabel : ""} {value}
          </span>
          <span className="icon__price-box__label">{description}</span>
        </div>
      </Box>
    </Grid>
  );
}
