// compare 2 objects (formData and schemaProperties) from JSON schema, in order to get object formData, but with translated title.
// but we are also sending "original", that we are using for EPs properties/payload --> and others are for translation purpose
export const getRepackedFormDataObj = (formData: any, schemaProperties: any): any => {
  if (!formData || !schemaProperties) {
    return null;
  }

  type DynamicSchema = Record<string, any>;
  // Define the function's return type
  type TransformedResult = {
    original: Record<string, any>;
    [key: string]: any;
  };

  const transformData = (schema: DynamicSchema, formData: any): TransformedResult => {
    const original: Record<string, any> = {};
    const transformed: Record<string, any> = {};
    const bookingUnits = [];

    for (const [key, properties] of Object.entries(schema)) {
      const value = formData[key];

      // Add to the original object
      if (properties?.type === "number" && properties?.offerPriceId) {
        bookingUnits.push({ quantity: value, id: properties.offerPriceId });
      } else {
        original[key] = value;
      }

      // Add the title and value as they are to the transformed object
      if (properties?.title) {
        transformed[properties.title] = value;
      }
    }

    if (bookingUnits?.length > 0) original.bookingUnits = bookingUnits;
    return {
      original,
      ...transformed
    };
  };
  const result = transformData(schemaProperties, formData);

  return Object.assign({}, result);
};
