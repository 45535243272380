import { isObjectEmpty } from "../../../../common/utils/helpers/isObjectEmpty";

export const getLocationText = (address: any = {}): string => {
  if (!address || isObjectEmpty(address)) {
    return "";
  }

  const street: string = address?.street || "";
  const streetNumber: string = address?.street_number || "";
  const city: string = address?.city || "";
  const zipCode: string = address?.zip_code || "";

  return `${street} ${streetNumber} ${"\n"} ${zipCode} ${city}`;
};
