import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images / icons
import { arrowRight } from "../../common/vector";

interface AppSwiperProps {
  images: any;
  customColor: string;
  defaultImage: string;
}

export default function AppSwiper({ images, defaultImage, customColor }: AppSwiperProps) {
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const PrevArrow = ({ onClick }: any) => (
    <button onClick={onClick} className="custom-arrow custom-arrow--prev">
      <img src={arrowRight.default} alt="arrow-prev" />
    </button>
  );

  const NextArrow = ({ onClick }: any) => (
    <button onClick={onClick} className="custom-arrow custom-arrow--next">
      <img src={arrowRight.default} alt="arrow-next" />
    </button>
  );

  const settings = {
    vertical: true, // Set vertical mode
    verticalSwiping: true, // Enable vertical swiping
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true
  };

  return (
    <div className="slider-box">
      <div
        className="slider-box__default-image-cover"
        style={{
          flex: images?.length > 1 ? "0 0 80%" : "0 0 100%",
          backgroundColor: customColor,
          backgroundImage: `url(${defaultImage})`,
          filter: `${images?.length > 0 ? "blur(0px)" : "blur(18px)"}`,
          transition: ".4s all linear"
        }}
      >
        <Slider
          className="slider-box__main-image-carousel"
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
          asNavFor={slider2}
          ref={(slider: any) => setSlider1(slider)}
        >
          {images?.map((image: any, index: number) => {
            const srcSet =
              image?.dimensions?.length > 0
                ? image.dimensions?.map((image: any, index: number) => `${image.url} ${image.width}w`).join(", ")
                : "";
            const src =
              image?.dimensions?.length > 0 && image.dimensions.find((size: any) => size.size === "large")?.url;
            return (
              <img
                key={index}
                alt="slider-img"
                srcSet={srcSet}
                src={src}
                sizes="(max-width: 600px) 580px,
                       (max-width: 900px) 420px,
                       (max-width: 1200px) 800px,
                       580px"
              />
            );
          })}
        </Slider>
      </div>

      {images?.length > 1 ? (
        <Slider
          dots={false}
          infinite={true}
          speed={500}
          arrows={false}
          slidesToScroll={1}
          slidesToShow={images?.length > 3 ? 4 : 2}
          asNavFor={slider1}
          className="slider-box__secondary-image-carousel slider-box__thumbnails--horizontal"
          focusOnSelect={true}
          ref={(slider: any): any => setSlider2(slider)}
          swipeToSlide={true}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2
              }
            }
          ]}
        >
          {images?.map((image: any, index: number) => {
            const srcSet =
              image?.dimensions?.length > 0
                ? image.dimensions?.map((image: any) => `${image.url} ${image.width}w`).join(", ")
                : "";
            const src =
              image?.dimensions?.length > 0 && image.dimensions.find((size: any) => size.size === "large")?.url;
            return (
              <img
                key={index}
                alt="slider-thumbnail"
                srcSet={srcSet}
                src={src}
                sizes="(max-width: 600px) 260px,
                (max-width: 900px) 260px,
                (max-width: 1200px) 260px,
                (max-width: 1600px) 580px,
                420px"
              />
            );
          })}
        </Slider>
      ) : null}

      {images?.length > 1 ? (
        <div className="slider-box__thumbnails">
          <Slider
            {...settings}
            dots={false}
            infinite={true}
            speed={500}
            arrows={false}
            slidesToScroll={1}
            slidesToShow={images?.length > 3 ? 4 : 2}
            asNavFor={slider1}
            className="slider-box__secondary-image-carousel slider-box__thumbnails--vertical"
            focusOnSelect={true}
            ref={(slider: any): any => setSlider2(slider)}
            swipeToSlide={true}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2
                }
              }
            ]}
          >
            {images?.map((image: any, index: number) => {
              const srcSet =
                image?.dimensions?.length > 0
                  ? image.dimensions?.map((image: any) => `${image.url} ${image.width}w`).join(", ")
                  : "";
              const src =
                image?.dimensions?.length > 0 && image.dimensions.find((size: any) => size.size === "large")?.url;
              return (
                <img
                  key={index}
                  alt="slider"
                  srcSet={srcSet}
                  src={src}
                  sizes="(max-width: 600px) 260px,
                (max-width: 900px) 260px,
                (max-width: 1200px) 260px,
                (max-width: 1600px) 580px,
                420px"
                />
              );
            })}
          </Slider>
        </div>
      ) : null}
    </div>
  );
}
