import ApiHelper from "../../utils/apiHelper";
import { checkVersion } from "../../utils/helpers/checkVersion";

export const globalActionTypes = {
  SET_INITIAL_CONFIG: "SET_INITIAL_CONFIG",
  SET_CURRENT_APP_VERSION: "SET_CURRENT_APP_VERSION",
  SET_VERSION_STATUS: "SET_VERSION_STATUS",
  TOGGLE_GLOBAL_LOADER: "TOGGLE_GLOBAL_LOADER",
  TOGGLE_LANGUAGE_MENU_DISABLE: "TOGGLE_LANGUAGE_MENU_DISABLE"
};

export const initialState = {
  apiUrl: null,
  defaultDomain: null,
  currentAppVersion: null,
  versionUpdateStatus: { status: false, version: "" },
  globalLoader: true,
  isLanguageMenuDisabled: false
};

interface globalModuleTypes {
  apiUrl: string | null;
  defaultDomain: string | null;
  currentAppVersion: string | null;
  globalLoader: boolean;
  isLanguageMenuDisabled: boolean;
}

const globalModule = (state: globalModuleTypes = initialState, action: any) => {
  switch (action.type) {
    case globalActionTypes.TOGGLE_GLOBAL_LOADER:
      return {
        ...state,
        globalLoader: action.globalLoader
      };
    case globalActionTypes.SET_INITIAL_CONFIG:
      return {
        ...state,
        apiUrl: action.appConfig.api_url,
        defaultDomain: action.appConfig.default_domain,
        googleMapApiKey: action.appConfig.google_map_api_key,
        googleAnalyticsTrackingId: action.appConfig.google_analytics_tracking_id
      };
    case globalActionTypes.SET_CURRENT_APP_VERSION:
      return {
        ...state,
        currentAppVersion: action.currentAppVersion
      };
    case globalActionTypes.SET_VERSION_STATUS:
      return {
        ...state,
        versionUpdateStatus: action.versionUpdateStatus
      };
    case globalActionTypes.TOGGLE_LANGUAGE_MENU_DISABLE:
      return {
        ...state,
        isLanguageMenuDisabled: action.isLanguageMenuDisabled
      };

    default:
      return state;
  }
};

export default globalModule;

export const globalActionCreators = {
  toggleGlobalLoaderAction: (globalLoader: boolean) => {
    return {
      type: globalActionTypes.TOGGLE_GLOBAL_LOADER,
      globalLoader
    };
  },
  toggleLanguageMenuDisableAction: (isLanguageMenuDisabled: boolean) => {
    return {
      type: globalActionTypes.TOGGLE_LANGUAGE_MENU_DISABLE,
      isLanguageMenuDisabled
    };
  },
  getConfigAction: () => async (dispatch: (arg0: { type: string; appConfig: any }) => void) => {
    try {
      const response = await ApiHelper.get(`${window.location.origin}/config.json`);

      dispatch({
        type: globalActionTypes.SET_INITIAL_CONFIG,
        appConfig: response.data
      });
    } catch (err) {
      return {};
    }
  },
  getCurrentAppVersionAction: () => async (dispatch: (arg0: { type: string; currentAppVersion: any }) => void) => {
    try {
      // Dynamically generate a timestamp for cache-busting
      const timestamp = new Date().getTime();

      const response = await ApiHelper.get(`${window.location.origin}/version.json?v=${timestamp}`);

      dispatch({
        type: globalActionTypes.SET_CURRENT_APP_VERSION,
        currentAppVersion: response.data?.tag
      });
    } catch (err) {
      return {};
    }
  },
  checkAppVersionStatusAction:
    () => async (dispatch: (arg0: { type: string; versionUpdateStatus: any }) => void, getState: any) => {
      try {
        const currentAppVersion = getState().global.currentAppVersion;
        // Dynamically generate a timestamp for cache-busting
        const timestamp = new Date().getTime();

        const response = await ApiHelper.get(`${window.location.origin}/version.json?v=${timestamp}`);
        const versionCheck = checkVersion(currentAppVersion?.substring(1), response?.data?.tag?.substring(1));

        dispatch({
          type: globalActionTypes.SET_VERSION_STATUS,
          versionUpdateStatus: versionCheck
        });
      } catch (err) {
        return {};
      }
    }
};
