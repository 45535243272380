// mui icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

export const getMenuLinkIcons = (icon: string, customIconStyle?: any): JSX.Element => {
  switch (icon) {
    case "info":
      return <InfoOutlinedIcon sx={customIconStyle} />;
    case "info_map":
      return <PlaceOutlinedIcon sx={customIconStyle} />;
    default:
      return <></>;
  }
};
