import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { decode } from "html-entities";
import { useTranslation } from "react-i18next";
// helpers
import { getOfferNameForBreadcrumb } from "./helpers/getOfferNameForBreadcrumb";
import { getBreadcrumbs } from "./helpers/getBreadcrumbs";
// types
import { CrumbsType } from "./types";

export default function AppBreadcrumbs() {
  const location = useLocation();
  const { t } = useTranslation();

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const offer = useSelector((state: any) => state.offerDetails.offer);
  const bookingStatus = useSelector((state: any) => state.confirmation.bookingStatus);
  const searchedQuery = useSelector((state: any) => state.explore.searchedQuery);
  const categories = useSelector((state: any) => state.explore.categories);

  // @ts-ignore
  const bookingState = JSON.parse(sessionStorage.getItem("bookingState"));

  const breadcrumbsList = (): Array<CrumbsType> => {
    const offerName: string = getOfferNameForBreadcrumb(
      offer?.title || bookingState?.state?.offer?.title,
      location?.state?.offer?.title,
      bookingStatus?.event_name
    );

    return getBreadcrumbs(
      location,
      hotelInfo?.name,
      offerName,
      searchedQuery,
      categories,
      t("breadcrumbs.searchResult"),
      t("breadcrumbs.categoryResult"),
      t("breadcrumbs.checkout"),
      t("breadcrumbs.confirmation")
    );
  };

  return (
    <Grid container sx={{ maxWidth: "1400px", margin: "0 auto" }} pt={1} pl={{ xs: 4.5 }}>
      <Breadcrumbs separator=":" aria-label="breadcrumb">
        {breadcrumbsList()?.map((crumb: CrumbsType, index: number) => {
          const isLastCrumb = breadcrumbsList()?.length - 1 === index;
          return (
            <Link
              data-cy={decode(crumb?.name)}
              key={crumb.href}
              underline="hover"
              href={crumb.href}
              sx={{
                color: hotelInfo?.style?.page?.main_color,
                fontSize: "15px",
                pointerEvents: isLastCrumb ? "none" : "auto"
              }}
            >
              {decode(crumb?.name)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
}
