import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { defaultLanguage, appLocale } from "./languagesConfig";
// helpers
import ApiHelper from "../apiHelper";

// Check if this can be moved (if this solution fits) after #3988 is tested
const getVersion = async () => {
  try {
    // Dynamically generate a timestamp for cache-busting
    const timestamp = new Date().getTime();

    const response = await ApiHelper.get(`${window.location.origin}/version.json?v=${timestamp}`);
    return response?.data?.tag;
  } catch (error) {
    return "fallback_version"; // Provide a fallback version if the API call fails
  }
};

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .use(HttpApi)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: appLocale.languages,
    // debug: true,
    fallbackLng: appLocale.default,
    detection: {
      order: ["path"],
      // caches: ["cookie"],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    backend: {
      // Old implementation - this can be removed (if this solution fits) after #3988 is tested
      // loadPath: "/assets/locales/{{lng}}/translation.json"
      loadPath: async (lng: string) => `/assets/locales/${lng}/translation.json?v=${await getVersion()}`
    }
    // react: {
    //   useSuspense: false
    // }
  });

export default i18n;
