export const supportedLanguages = [
  { abr: "en", language: "English" },
  { abr: "fr", language: "French" },
  { abr: "de", language: "Deutsch" },
  { abr: "it", language: "Italian" }
];

export const defaultLanguage = "en";

export const appLocale = {
  default: "en",
  languages: ["en", "fr", "de", "it"]
};
