import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// components
import AppModal from "../../../components/ui/AppModal";
import StripeCheckoutForm from "../../../components/Payment/Stripe/StripeCheckoutForm";
import AdyenCheckoutForm from "../../../components/Payment/Adyen/AdyenCheckoutForm";
// actions
import { useActions } from "../../../common/hooks/useActions";
import { bookingActionCreators } from "../bookingModule";
import { paymentActionCreators } from "../../../common/store/modules/paymentModule";

interface BookingPaymentModalProps {
  isOpen: boolean;
  offerCode: string;
}

export default function BookingPaymentModal({ isOpen, offerCode }: BookingPaymentModalProps) {
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState(null);

  const language = localStorage.getItem("i18nextLng");

  // states
  const bookingResponseData = useSelector((state: any) => state.booking.bookingResponseData);
  const bookingPaymentInfo = useSelector((state: any) => state.payment.bookingPaymentInfo);
  const paymentPlatform = useSelector((state: any) => state.payment.paymentPlatform);
  const bookingPaymentError = useSelector((state: any) => state.payment.bookingPaymentError);
  // actions
  const togglePaymentModal = useActions(bookingActionCreators?.togglePaymentModalAction, []);
  const getBookingPaymentInfo = useActions(paymentActionCreators?.getBookingPaymentInfoAction, []);
  const clearBookingPaymentInfo = useActions(paymentActionCreators?.clearBookingPaymentInfoAction, []);

  useEffect(() => {
    if (bookingResponseData) {
      getBookingPaymentInfo(bookingResponseData?.booking_code);
    }
    return () => {
      clearBookingPaymentInfo();
      handleCloseModal();
    };
  }, []);

  useEffect(() => {
    if (bookingPaymentInfo?.stripe_public_key) {
      // @ts-ignore
      setStripePromise(loadStripe(bookingPaymentInfo?.stripe_public_key, { locale: language }));
    }
  }, [bookingPaymentInfo]);

  const handleOpenModal = (): void => {
    togglePaymentModal(true);
  };

  const handleCloseModal = (): void => {
    togglePaymentModal(false);
  };

  const renderStripeContent = () => {
    return (
      <Box mt={2} ml="auto" mr="auto">
        {bookingPaymentInfo?.client_secret && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: bookingPaymentInfo?.client_secret, loader: "always" }}
          >
            <StripeCheckoutForm
              confirmationUrl={`${window.location.origin}/${language}/offers/${offerCode}/checkout/confirmation`}
            />
          </Elements>
        )}
      </Box>
    );
  };

  const renderAdyenContent = () => {
    return <AdyenCheckoutForm confirmationUrl={`/${language}/offers/${offerCode}/checkout/confirmation`} />;
  };

  return (
    <AppModal
      title={t("checkout.title")}
      modalSize="md"
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      handleOpenModal={handleOpenModal}
    >
      {paymentPlatform === "stripe" ? (
        renderStripeContent()
      ) : paymentPlatform === "adyen" ? (
        renderAdyenContent()
      ) : bookingPaymentError ? (
        <div>{t("error.unexpected")}</div>
      ) : (
        <div />
      )}
    </AppModal>
  );
}
