import { isObjectEmpty } from "../../../common/utils/helpers/isObjectEmpty";

export const checkIfExtraOptionsValidate = (extraOptionsSchema: any, extraOptionsSchemaFormData: any): boolean => {
  const isOptionsRequired = extraOptionsSchema?.json_schema?.required?.includes("options");
  if (isOptionsRequired) {
    return !isObjectEmpty(extraOptionsSchemaFormData || {});
  } else {
    return true;
  }
};
