import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";
// components
import AppHeader from "../AppHeader";
import AppFooter from "../AppFooter";
import AppAcceptCookiesBox from "../AppAcceptCookiesBox";
import AppReloadModal from "./AppReloadModal";
import GlobalLoader from "../GlobalLoader";
import AppBreadcrumbs from "../AppBreadcrumbs";
import AppHelmet from "../AppHelmet";
// hooks
import { useActions } from "../../common/hooks/useActions";
// actions
import { globalActionCreators } from "../../common/store/modules/globalModule";
import { hotelActionCreators } from "../../common/store/modules/hotelModule";
// helpers
import { getMainSubdomainUrl } from "../../common/utils/helpers/subdomainHelpers/getMainSubdomainUrl";
import { checkIsDefaultDomain } from "../../common/utils/helpers/subdomainHelpers/checkIsDefaultDomain";
// icons
import { logoIconDefault } from "../../common/vector";

export default function AppLayout() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies(["cookieAccepted"]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showUpdateBumpFlag, setShowUpdateBumpFlag] = useState(false);
  const [isMajorVersionReloadRequired, setIsMajorVersionReloadRequired] = useState(false);

  const language = localStorage.getItem("i18nextLng");

  // actions
  const getConfig = useActions(globalActionCreators?.getConfigAction, []);
  const getHotelInfo = useActions(hotelActionCreators?.getHotelInfoAction, []);
  const getCurrentAppVersion = useActions(globalActionCreators?.getCurrentAppVersionAction, []);
  const checkAppVersionStatus = useActions(globalActionCreators?.checkAppVersionStatusAction, []);
  // states
  const globalLoader = useSelector((state: any) => state.global.globalLoader);
  const defaultDomain = useSelector((state: any) => state.global.defaultDomain);
  const googleAnalyticsTrackingId = useSelector((state: any) => state.global.googleAnalyticsTrackingId);
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const currentAppVersion = useSelector((state: any) => state.global.currentAppVersion);
  const versionUpdateStatus = useSelector((state: any) => state.global.versionUpdateStatus);

  const isDefaultDomain: boolean = checkIsDefaultDomain(window.location.hostname, defaultDomain);
  const isNotFoundRoute: boolean = location?.pathname === `/${language}/*`;
  const defaultAppHeaderColor = theme.palette.primary.dark;
  const defaultAppHeaderLogo = logoIconDefault.default;

  useEffect(() => {
    getConfig();
    getCurrentAppVersion();
  }, []);

  useEffect(() => {
    // Set interval to check version every minute
    const interval = setInterval(function () {
      checkAppVersionStatus();
    }, 60000);

    if (versionUpdateStatus?.status && !showUpdateBumpFlag) {
      setIsModalOpen(true);
      if (versionUpdateStatus?.version === "major") {
        setIsMajorVersionReloadRequired(true);
      }
    }

    // clear interval
    if (showUpdateBumpFlag) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [showUpdateBumpFlag, currentAppVersion, versionUpdateStatus]);

  useEffect(() => {
    // call hotel info
    if (!isDefaultDomain && defaultDomain) {
      const hotelUrl: string = getMainSubdomainUrl(window.location.hostname, defaultDomain);
      getHotelInfo(hotelUrl, true, navigate);
    }
  }, [defaultDomain]);

  // Google Analytics Initialization
  useEffect(() => {
    // GA4: Initialize Google Analytics if cookies has been accepted
    if (googleAnalyticsTrackingId && cookies.cookieAccepted) {
      ReactGA.initialize(googleAnalyticsTrackingId);
    }
  }, [googleAnalyticsTrackingId, cookies.cookieAccepted]);

  const handleExploreIconClick = () => {
    navigate(`/${language}`, { replace: true });
  };

  return (
    <div className="app-layout" data-cy="guest">
      {/* Set metadata */}
      <AppHelmet isDefaultDomain={isDefaultDomain} />
      {/* // */}

      {showUpdateBumpFlag && (
        <Box
          className="update-flag"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          <Box sx={{ position: "relative" }}>
            {t("version.updateFlag")}{" "}
            <span className="update-flag__description">{t("version.updateFlagDescription")}</span>
          </Box>
          <span className="shape"></span>
        </Box>
      )}
      {/* show global loader only for hotels (subdomains) */}
      {globalLoader && !isDefaultDomain && <GlobalLoader />}
      {isDefaultDomain ? (
        <AppHeader color={defaultAppHeaderColor} logo={defaultAppHeaderLogo} showLanguageIcon={!isNotFoundRoute} />
      ) : (
        <AppHeader
          color={isNotFoundRoute || !hotelInfo ? defaultAppHeaderColor : hotelInfo?.style?.page?.main_color}
          logo={isNotFoundRoute || !hotelInfo ? defaultAppHeaderLogo : hotelInfo?.style?.logo}
          showLanguageIcon={!isNotFoundRoute}
          showExploreIcon={!isNotFoundRoute}
          handleExploreIconClick={handleExploreIconClick}
          menuLinks={hotelInfo?.menu?.links}
        />
      )}
      {!isDefaultDomain && <AppBreadcrumbs />}
      <Box className="app-container">
        <Outlet />
        <AppReloadModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isMajorVersionReloadRequired={isMajorVersionReloadRequired}
          setShowUpdateBumpFlag={setShowUpdateBumpFlag}
        />
        {typeof cookies.cookieAccepted !== "boolean" && <AppAcceptCookiesBox />}
      </Box>
      <AppFooter isDefaultDomain={isDefaultDomain} />
    </div>
  );
}
