import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import ReactGA from "react-ga4";
// components
import AppDatePickerCustom from "../../../components/AppDatePickerCustom";
import SbbRowAction from "./SbbRowAction";
import SbbQuickSelection from "./SbbQuickSelection";
import SbbPassengersForm from "./SbbPassengersForm";
import SbbRowAction2Buttons from "./SbbRowAction/SbbRowAction2Buttons";
import SbbSummary from "./SbbSummary";
import SbbBookingError from "./SbbBookingError";
import SbbPaymentModal from "./SbbPaymentModal";
import AppTimeComponent from "../../../components/AppTimeComponent";
import AppCustomRouteAsync from "../../../components/AppCustomRouteAsync";
import AppButton from "../../../components/ui/AppButton";
// helpers
import { checkForEmptyNames } from "./helpers/checkForEmptyNames";
import { hoursUpdate } from "../../../components/AppTimeComponent/helpers/updateHours";
import { updateMinutes } from "../../../components/AppTimeComponent/helpers/updateMinutes";
import { checkIsItInFuture } from "../../../common/utils/helpers/checkIsItInFuture";
import { isObjectEmpty } from "../../../common/utils/helpers/isObjectEmpty";
// hooks
import { useActions } from "../../../common/hooks/useActions";
// actions
import { sbbActionCreators } from "./sbbModule";
// icons
import { dateIcon, serviceClassIcon, timeIcon, directionIcon } from "../../../common/vector";

type OptionType = { value: string; label: string; stationId: number };

export default function Sbb() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng");
  const [searchParams, setSearchParams] = useSearchParams();
  const quickSelectParam: string | null = searchParams.get("qs");
  const fromParam: string | null = searchParams.get("from");
  const toParam: string | null = searchParams.get("to");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [serviceClass, setServiceClass] = useState(2);
  const [direction, setDirection] = useState("outward");
  const [sbbHours, setSbbHours] = useState(new Date().getHours());
  const [sbbMinutes, setSbbMinutes] = useState(new Date().getMinutes());
  // Routes
  const [selectedFromStation, setSelectedFromStation] = useState<OptionType | null>(null);
  const [selectedToStation, setSelectedToStation] = useState<OptionType | null>(null);
  const [disableCustomRouteAsync, setDisableCustomRouteAsync] = useState(false);

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const sbbQuickSelection = useSelector((state: any) => state.sbb.sbbQuickSelection);
  const quickSelectionError = useSelector((state: any) => state.sbb.quickSelectionError);
  const sbbCustomRouteCode = useSelector((state: any) => state.sbb.sbbCustomRouteCode);
  const sbbActiveOffer = useSelector((state: any) => state.sbb.sbbActiveOffer);
  const sbbData = useSelector((state: any) => state.sbb.sbbData);
  const selectedStationFrom = useSelector((state: any) => state.sbb.selectedStationFrom);
  const selectedStationTo = useSelector((state: any) => state.sbb.selectedStationTo);
  const passengerList = useSelector((state: any) => state.sbb.passengerList);
  const errorPrebook = useSelector((state: any) => state.sbb.errorPrebook);
  const prebookPriceInfo = useSelector((state: any) => state.sbb.prebookPriceInfo);
  const sbbBookingError = useSelector((state: any) => state.sbb.sbbBookingError);
  const showSbbPaymentModal = useSelector((state: any) => state.sbb.showSbbPaymentModal);
  const globalLoader = useSelector((state: any) => state.global.globalLoader);
  const apiUrl = useSelector((state: any) => state.global.apiUrl);

  // actions
  const getSbbInfo = useActions(sbbActionCreators?.getSbbInfoAction, []);
  const searchSbbStations = useActions(sbbActionCreators?.searchSbbStationsAction, []);
  const getPriceSbbPrebook = useActions(sbbActionCreators?.getPriceSbbPrebookAction, []);
  const buySbbTicket = useActions(sbbActionCreators?.buySbbTicketAction, []);
  const setSbbActiveOffer = useActions(sbbActionCreators?.setSbbActiveOfferAction, []);
  const resetSbbState = useActions(sbbActionCreators?.resetSbbStateAction, []);

  useEffect(() => {
    // GA4 - track SBB pageview
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Custom SBB pageview" });

    updateMinutes(setSbbMinutes, setSbbHours, "initial", 15, sbbHours, sbbMinutes);

    // reset redux state on unmount
    return () => {
      resetSbbState();
    };
  }, []);

  useEffect(() => {
    if (hotelInfo) {
      getSbbInfo();
    }
  }, [hotelInfo]);

  useEffect(() => {
    if (quickSelectParam) {
      const selectedOffer = sbbQuickSelection?.find((sbbOffer: any): any => sbbOffer?.code === quickSelectParam);
      setSbbActiveOffer(selectedOffer);

      handleSbbQuickSelection(selectedOffer, quickSelectParam);
    }
  }, [language, sbbQuickSelection]);

  useEffect(() => {
    if (fromParam) {
      searchSbbStations(fromParam, "from");
      // Call the async function
    }
    if (toParam) {
      searchSbbStations(toParam, "to");
      // Call the async function
    }
  }, [apiUrl]);

  // Set selected From and To stations
  useEffect(() => {
    if (selectedStationFrom?.[0]) {
      onChangeFromAutoComplete(selectedStationFrom?.[0]);
    }
  }, [selectedStationFrom]);
  useEffect(() => {
    if (selectedStationTo?.[0]) {
      onChangeToAutoComplete(selectedStationTo?.[0]);
    }
  }, [selectedStationTo]);

  const resetSelectedSbbActiveOffer = () => {
    if (!isObjectEmpty(sbbActiveOffer) || sbbActiveOffer?.code) {
      setSbbActiveOffer({});
      navigate(`/${language}/transport/sbb`, { replace: true });
    }
  };

  const onChangeFromAutoComplete = (station: any) => {
    resetSelectedSbbActiveOffer();

    if (station?.name) {
      // Set selected From station to selected value
      setSelectedFromStation({ value: station?.name, label: station?.name, stationId: station?.station_id });

      if (toParam) {
        navigate(`/${language}/transport/sbb?from=${station?.name}&to=${toParam}`, { replace: true });
      } else {
        navigate(`/${language}/transport/sbb?from=${station?.name}`, { replace: true });
      }
    } else {
      // Set selected From station to null
      setSelectedFromStation(null);

      if (toParam) {
        navigate(`/${language}/transport/sbb?to=${toParam}`, { replace: true });
      } else {
        navigate(`/${language}/transport/sbb`, { replace: true });
      }
    }
  };

  const onChangeToAutoComplete = (station: any) => {
    resetSelectedSbbActiveOffer();
    if (station?.name) {
      // Set selected TO station to null
      setSelectedToStation({ value: station?.name, label: station?.name, stationId: station?.station_id });

      if (fromParam) {
        navigate(`/${language}/transport/sbb?from=${fromParam}&to=${station?.name}`, { replace: true });
      } else {
        navigate(`/${language}/transport/sbb?to=${station?.name}`, { replace: true });
      }
    } else {
      // Set selected TO station to null
      setSelectedToStation(null);

      if (fromParam) {
        navigate(`/${language}/transport/sbb?from=${fromParam}`, { replace: true });
      } else {
        navigate(`/${language}/transport/sbb`, { replace: true });
      }
    }
  };

  const handleSbbQuickSelection = (offer: any, offerCode: string) => {
    setSbbActiveOffer(offer);
    setDisableCustomRouteAsync(true);
    // deselect custom routes
    selectedFromStation && setSelectedFromStation(null);
    selectedToStation && setSelectedToStation(null);

    navigate(`/${language}/transport/sbb?qs=${offerCode}`, { replace: true });
  };

  const handleSeePriceBtn = () => {
    const startDate: Date = selectedDate;
    startDate.setHours(sbbHours);
    startDate.setMinutes(sbbMinutes);

    getPriceSbbPrebook({
      qualityOfService: serviceClass,
      direction: sbbActiveOffer?.code ? null : direction,
      start_date: format(startDate, "yyyy-MM-dd-HH:mm"),
      offer_code: sbbActiveOffer?.code ? sbbActiveOffer?.code : sbbCustomRouteCode,
      origin: selectedFromStation
        ? {
            name: selectedFromStation?.value,
            station_id: selectedFromStation?.stationId
          }
        : null,
      destination: selectedToStation
        ? {
            name: selectedToStation?.value,
            station_id: selectedToStation?.stationId
          }
        : null
    });
  };

  const handleBuyTicketBtn = () => {
    buySbbTicket(
      {
        start_date: Math.round(+new Date(selectedDate) / 1000), // get unix timestamp
        timeslot: `${sbbHours}:${sbbMinutes}`,
        offer_code: sbbActiveOffer?.code ? sbbActiveOffer?.code : sbbCustomRouteCode
      },
      navigate
    );
  };

  const handleValidationSeePriceBtn = (): boolean => {
    const isSelectedRoute: boolean = (selectedFromStation && selectedToStation) || sbbActiveOffer?.code;
    return (
      !isSelectedRoute || !checkIsItInFuture(selectedDate, sbbHours, sbbMinutes, 0) || checkForEmptyNames(passengerList)
    );
  };

  const renderPrebookErrorMsg = () => {
    if (checkIsItInFuture(selectedDate, sbbHours, sbbMinutes, 0) && !errorPrebook) {
      return null;
    }
    let errors: Array<any> = [];
    if (!checkIsItInFuture(selectedDate, sbbHours, sbbMinutes, 0)) {
      errors.push({
        errorMessage: t("transport.timeErrorMsg")
      });
    }
    if (errorPrebook?.fields) {
      errors = errors?.concat(errorPrebook?.fields);
    }

    if (!errors?.length) {
      errors.push({
        errorMessage: t("transport.prebookCustomError")
      });
    }

    return errors?.map((error: any, i: number) => (
      <div key={i} className="sbb__error-msg">
        {error?.field && `${error?.field} -`} {error?.errorMessage}
      </div>
    ));
  };

  const getDisabledStyleForTicketsForm = () => {
    if (prebookPriceInfo) {
      return { opacity: 0.4, pointerEvents: "none" };
    }
  };

  return (
    <Grid
      className="sbb grid-box"
      item
      sx={{ flexDirection: "column", flex: 1, margin: 0, padding: 0 }}
      mt={{ xs: 1, sm: 3 }}
      mb={{ xs: 4, sm: 5 }}
    >
      {sbbBookingError ? (
        <SbbBookingError />
      ) : (
        <>
          <Grid item xs={12} pb={4} pl={4.5}>
            <div className="sbb__title">{sbbData?.name}</div>
          </Grid>

          {(sbbQuickSelection?.length > 0 || sbbCustomRouteCode) && !quickSelectionError ? (
            <Grid container>
              <Grid
                item
                sx={{ display: "flex", flexDirection: "column", ...getDisabledStyleForTicketsForm() }}
                md={6}
                xs={12}
              >
                <SbbRowAction
                  label={t("transport.departureDate")}
                  icon={dateIcon?.default}
                  actionComponent={
                    <Grid item width={{ xs: 200, sm: 220, md: 260 }}>
                      <AppDatePickerCustom
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        minDate={new Date()}
                      />
                    </Grid>
                  }
                />
                <SbbRowAction
                  label={t("transport.departureTime")}
                  icon={timeIcon?.default}
                  actionComponent={
                    <Grid item width={{ xs: 200, sm: 220, md: 260 }}>
                      <AppTimeComponent
                        hours={sbbHours}
                        minutes={sbbMinutes}
                        handleHoursChange={(change: string, amount: number) =>
                          hoursUpdate(setSbbHours, change, amount, sbbHours)
                        }
                        handleMinutesChange={(change: string, amount: number) =>
                          updateMinutes(setSbbMinutes, setSbbHours, change, amount, sbbHours, sbbMinutes)
                        }
                        timeBoxSize="w-80"
                      />
                    </Grid>
                  }
                />
                <SbbRowAction
                  label={t("transport.serviceClass")}
                  icon={serviceClassIcon?.default}
                  actionComponent={
                    <SbbRowAction2Buttons
                      firstButtonLabel={"1"}
                      firstButtonHandle={() => setServiceClass(1)}
                      secondButtonLabel={"2"}
                      secondButtonHandle={() => setServiceClass(2)}
                      isFirstButtonActive={serviceClass === 1}
                    />
                  }
                />
                <SbbRowAction
                  label={t("transport.direction")}
                  icon={directionIcon?.default}
                  isDisabled={sbbActiveOffer?.code}
                  actionComponent={
                    <SbbRowAction2Buttons
                      firstButtonLabel={t("transport.oneWayBtn")}
                      firstButtonHandle={() => setDirection("outward")}
                      secondButtonLabel={t("transport.returnBtn")}
                      secondButtonHandle={() => setDirection("round")}
                      isFirstButtonActive={direction === "outward"}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                mt={{ sm: 6, xs: 6, md: 0 }}
                sx={{ ...getDisabledStyleForTicketsForm() }}
                position={"relative"}
              >
                <div className="vertical-divider" />
                <Box pl={{ xs: 2, sm: 6 }} pr={{ xs: 2, sm: 4 }}>
                  <AppCustomRouteAsync
                    setDisableCustomRouteAsync={setDisableCustomRouteAsync}
                    disableCustomRouteAsync={disableCustomRouteAsync}
                    selectedToStation={selectedToStation}
                    selectedFromStation={selectedFromStation}
                    onInputChange={searchSbbStations}
                    onFocusCustomRouteAsync={resetSelectedSbbActiveOffer}
                    firstInputId="search-sbb-from-input"
                    secondInputId="search-sbb-to-input"
                    setSelectedFromStation={onChangeFromAutoComplete}
                    setSelectedToStation={onChangeToAutoComplete}
                  />
                  {sbbQuickSelection?.length > 0 && (
                    <SbbQuickSelection
                      handleSbbQuickSelection={handleSbbQuickSelection}
                      quickSelectParam={quickSelectParam}
                    />
                  )}
                  <SbbPassengersForm />
                  {renderPrebookErrorMsg()}
                  <Grid item xs={12} sm={4} sx={{ opacity: handleValidationSeePriceBtn() ? 0.8 : 1 }}>
                    <AppButton
                      label={t("transport.seePriceBtn")}
                      onClick={handleSeePriceBtn}
                      disableRipple={true}
                      customBackgroundColor={hotelInfo?.style?.button?.color}
                      customActiveColor={hotelInfo?.style?.button?.active}
                      customHoverColor={hotelInfo?.style?.button?.hover}
                      customClickColor={hotelInfo?.style?.button?.click}
                      fontSize="14px"
                      disabled={handleValidationSeePriceBtn()}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} mt={{ xs: 4, sm: 6 }} ml={{ xs: 2, sm: 5, md: 6 }} mr={{ xs: 2, sm: 5, md: 6 }}>
                <SbbSummary onBuyTicket={handleBuyTicketBtn} />
              </Grid>
            </Grid>
          ) : (
            !globalLoader && (
              <Grid item xs={12} pb={4}>
                <Box sx={{ fontSize: 18 }}>NOT AVAILABLE</Box>
              </Grid>
            )
          )}
        </>
      )}
      {showSbbPaymentModal && <SbbPaymentModal isOpen={showSbbPaymentModal} />}
    </Grid>
  );
}
