import ApiHelper from "../../utils/apiHelper";

export const paymentActionTypes = {
  SET_PAYMENT_INFO: "SET_PAYMENT_INFO",
  TOGGLE_PAYMENT_ERROR: "TOGGLE_PAYMENT_ERROR"
};

export const initialState = {
  bookingPaymentInfo: null,
  bookingPaymentError: false,
  paymentPlatform: null
};

const paymentModule = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentActionTypes.SET_PAYMENT_INFO:
      return {
        ...state,
        bookingPaymentInfo: action.bookingPaymentInfo,
        paymentPlatform: action.paymentPlatform
      };
    case paymentActionTypes.TOGGLE_PAYMENT_ERROR:
      return {
        ...state,
        bookingPaymentError: action.bookingPaymentError
      };

    default:
      return state;
  }
};

export default paymentModule;

export const paymentActionCreators = {
  getBookingPaymentInfoAction: (bookingCode: string) => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;

    dispatch(paymentActionCreators?.setBookingPaymentErrorAction(false));

    try {
      const response = await ApiHelper.post(`${apiUrl}/payment/booking/${bookingCode}`, {}, {});

      dispatch({
        type: paymentActionTypes.SET_PAYMENT_INFO,
        bookingPaymentInfo: response.data?.data,
        paymentPlatform: response?.data?.platform
      });
    } catch (err: any) {
      dispatch(paymentActionCreators?.setBookingPaymentErrorAction(true));
    } finally {
    }
  },
  clearBookingPaymentInfoAction: () => (dispatch: any) => {
    dispatch({
      type: paymentActionTypes.SET_PAYMENT_INFO,
      bookingPaymentInfo: null
    });
  },
  setBookingPaymentErrorAction: (bookingPaymentError: boolean) => (dispatch: any) => {
    dispatch({
      type: paymentActionTypes.TOGGLE_PAYMENT_ERROR,
      bookingPaymentError
    });
  }
};
