import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// helpers
// components
import AppModal from "../../components/ui/AppModal";
// import AdyenCheckoutForm from "../../components/Payment/Adyen/AdyenCheckoutForm";
// actions
import { globalActionCreators } from "../../common/store/modules/globalModule";

export default function TestPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAdyen, setOpenAdyen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // not show global loader on this component
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
  }, []);

  return (
    <Grid sx={{ display: "flex", flex: 1, flexDirection: "column" }} container>
      <h2>Test Page for testing purposes</h2>
      <AppModal
        title="Test Modal Title"
        isOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        handleOpenModal={() => setIsModalOpen(true)}
      >
        <div> Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</div>
      </AppModal>

      <Box
        sx={{ backgroundColor: "aliceblue", width: 100, border: "1px solid black", cursor: "pointer" }}
        m={4}
        onClick={() => setOpenAdyen(true)}
      >
        open adyen
      </Box>

      {/*{openAdyen && <AdyenCheckoutForm />}*/}
    </Grid>
  );
}
