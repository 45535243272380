import { useEffect, useState } from "react";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// helpers
import { getCurrentDevice } from "../../../../common/utils/helpers/currentDeviceHelpers/getCurrentDevice";

interface SbbQuickSelectionTypes {
  handleSbbQuickSelection: (offer: any, offerCode: string) => void;
  quickSelectParam: string | null;
}

const slidesToShowDesktop: number = 4;
const slidesToShowMobile: number = 3;

const settings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: slidesToShowDesktop,
  slidesToScroll: slidesToShowDesktop,
  vertical: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: slidesToShowMobile,
        slidesToScroll: slidesToShowMobile
      }
    }
  ]
};

export default function SbbQuickSelection({ handleSbbQuickSelection, quickSelectParam }: SbbQuickSelectionTypes) {
  const [initialSlide, setInitialSlide] = useState(-1); // Should be 0 or bigger in order to show Slider

  const { t } = useTranslation();

  // states
  const sbbQuickSelection = useSelector((state: any) => state.sbb.sbbQuickSelection);
  const sbbActiveOffer = useSelector((state: any) => state.sbb.sbbActiveOffer);

  useEffect(() => {
    // Handling initial slide, 0 if coming
    if (quickSelectParam) {
      const selectedIndex = sbbQuickSelection?.findIndex((sbbOffer: any) => sbbOffer?.code === quickSelectParam);
      const slidesToShow = getCurrentDevice() === "phone" ? slidesToShowMobile : slidesToShowDesktop;
      // Formula for moving slider to the index of selected SBB selection
      const getInitialSliderIndex = Math.floor(selectedIndex / slidesToShow) * slidesToShow;

      setInitialSlide(getInitialSliderIndex);
    } else {
      setInitialSlide(0);
    }
  }, [sbbQuickSelection]);

  return (
    <Box mt={{ xs: 3, sm: 2 }} mb={1}>
      <Box className="sbb-quick-selection-title">{t("transport.quickSelection")}</Box>
      <Box ml={{ xs: 2, sm: 1 }} mr={{ xs: 2, sm: 1 }}>
        {initialSlide >= 0 && sbbQuickSelection?.length > 0 && (
          <Slider {...settings} initialSlide={initialSlide}>
            {sbbQuickSelection?.length > 0 &&
              sbbQuickSelection?.map((offer: any) => (
                <Box
                  key={offer?.code}
                  className={`sbb-quick-selection-item ${
                    offer?.code === sbbActiveOffer?.code ? "sbb-quick-selection-item__active" : ""
                  }`}
                  onClick={() => handleSbbQuickSelection(offer, offer?.code)}
                >
                  {offer?.name}
                </Box>
              ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
}
