export const downloadBookingPdf = (urlData: any, bookingCode: string): any => {
  // Download PDF
  const url = URL.createObjectURL(urlData);
  const a = document.createElement("a");
  a.download = `${bookingCode}-confirmation.pdf`;
  a.href = url;
  a.target = "_self";
  a.click();
  setTimeout(function () {
    // For Firefox, it is necessary to delay revoking the ObjectURL
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
};
