import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// components
import AppReadMore from "../../AppReadMore";
// images / icons
import { favoriteInactiveIcon, noImgPlaceholder } from "../../../common/vector";

interface AppOfferCardProps {
  name: string;
  description?: string;
  id: number;
  image: any | undefined;
  price: any;
  onClick: (id: number, name: string, isPromo?: boolean) => void;
  isFavorite?: boolean;
  customColor: string;
  priceLabel?: string;
  isPromo?: boolean;
}

export default function AppOfferCard({
  name,
  description,
  id,
  image,
  price,
  onClick,
  isFavorite,
  customColor,
  priceLabel,
  isPromo
}: AppOfferCardProps) {
  const [toggleCollapsible, setIsColapsible] = useState(false);

  const handleCollapsible = () => {
    setIsColapsible(!toggleCollapsible);
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      {/* card */}
      <Box onClick={() => onClick(id, name, isPromo)} className="card card--box" data-cy="card-box">
        {/* card header */}
        <div className="card__header" data-cy="card-header">
          {image ? (
            <img
              alt="card-img"
              srcSet={
                image.dimensions?.[0]?.width
                  ? `${image.dimensions?.[0]?.url} ${image.dimensions?.[0]?.width}w,${image.dimensions?.[1]?.url} ${image.dimensions?.[1]?.width}w,${image.dimensions?.[2]?.url} ${image.dimensions?.[2]?.width}w`
                  : ""
              }
              src={image.dimensions?.[0]?.url || noImgPlaceholder}
              sizes="(max-width: 600px) 40vw,
                (max-width: 900px) 361px,
                (max-width: 1200px) 494px,
                494px"
            />
          ) : (
            <img src={noImgPlaceholder} alt="no-img-placeholder" />
          )}
          {isFavorite && (
            <div className="card__header__favorite">
              <img src={favoriteInactiveIcon.default} alt="favorite-icon" />
            </div>
          )}
        </div>
        {/* card body */}
        <Box className="card__body" sx={{ backgroundColor: customColor }} data-cy="card-body">
          <span className="card__title">{name}</span>
          <div className="card__price-box">
            <span className="card__price-box__price">
              {price.currency} {price.amount}
            </span>
            <span className="card__price-box__label">{priceLabel}</span>
          </div>
        </Box>
        {/* card description for desktop and tablet */}
        <Box className={`card__description ${description ? "card__description--desktop" : ""}`}>
          <Box className="card__description__content">{description}</Box>
        </Box>
      </Box>

      {/* card description for mobile */}
      {description && (
        <Box
          onClick={handleCollapsible}
          className="card__description card__description--mobile"
          data-cy="card-read-more"
        >
          {/* read more box on mobile */}

          <Box
            className="card__read-more-box"
            sx={{ marginLeft: "auto", position: "absolute", top: "-42px", right: 0 }}
          >
            <AppReadMore toggleCollapsible={toggleCollapsible} size="sm" />
          </Box>

          {/* description on mobile */}
          <Box
            data-cy="card-description-mobile"
            onClick={() => onClick(id, name, isPromo)}
            className="card__description__content__text"
            sx={{
              transformOrigin: "top",
              maxHeight: toggleCollapsible ? "300px" : "0px",
              transform: toggleCollapsible ? "scaleY(1)" : "scaleY(0)",
              opacity: toggleCollapsible ? 1 : 0,
              padding: 0,
              width: "100%",
              transition: "all .3s ease-out",
              border: toggleCollapsible ? `1px solid ${customColor}` : "none",
              borderTop: "none",
              overflowY: "scroll"
            }}
          >
            <Box
              sx={{
                color: "inherit",
                margin: "15px"
              }}
            >
              {description}
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  );
}
