import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { components } from "react-select";
// @ts-ignore
import AsyncSelect from "react-select/async";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
// icons
import { fromIcon, toIcon } from "../../common/vector";
// hooks
import useAsyncHook from "../../common/hooks/useAsyncHook";
// helpers
import { checkIfStartsWithGivenString } from "../../common/utils/helpers/checkIfStartsWithGivenString";
import { getLengthOfStringBeforeGivenWordWithoutSpaces } from "../../common/utils/helpers/getLengthOfStringBeforeGivenWordWithoutSpaces";

const AppCustomRouteAsync = ({
  setDisableCustomRouteAsync,
  disableCustomRouteAsync,
  selectedToStation,
  selectedFromStation,
  onInputChange,
  error,
  onFocusCustomRouteAsync,
  firstInputId,
  secondInputId,
  setSelectedFromStation,
  setSelectedToStation
}: any) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [onFirstInputClick, setOnFirstInputClick] = useState(false);
  const [onSecondInputClick, setOnSecondInputClick] = useState(false);

  // COMPUTED WIDTHS FOR HANDLING WIDTH OF MENU LIST
  const [borderWidth, setBorderWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const elementRef = useRef(null);

  // for react-select async calls
  const loadOptionsFn = (keyword: any) => onInputChange(keyword);
  const { noOptionMessages, loadOptions } = useAsyncHook(loadOptionsFn);

  useEffect(() => {
    // GET COMPUTED WIDTHS AND UPDATE STATE FOR HANDLING MENU LIST WIDTH
    // @ts-ignore
    setBorderWidth(getComputedStyle(elementRef.current)?.borderInlineWidth);
    // @ts-ignore
    setContainerWidth(getComputedStyle(elementRef.current)?.width);
  }, []);

  const SingleValueInputFirst = ({ children, ...props }: any) => {
    const mainText = props?.data?.value;

    return (
      <components.SingleValue {...props}>
        <>
          <div className="main-text">
            {selectedFromStation?.value ? selectedFromStation.value : mainText ? mainText : "/"}
          </div>
        </>
      </components.SingleValue>
    );
  };
  const SingleValueInputSecond = ({ children, ...props }: any) => {
    const mainText = props?.data?.value;
    return (
      <components.SingleValue {...props}>
        <>
          <div className="main-text">
            {selectedToStation?.value ? selectedToStation.value : mainText ? mainText : "/"}
          </div>
        </>
      </components.SingleValue>
    );
  };

  const PlaceholderFromInput = (props: any) => {
    return (
      <>
        {(props?.value && !disableCustomRouteAsync) ||
        (onFirstInputClick && props.isFocused && !disableCustomRouteAsync) ? null : (
          <components.Placeholder {...props}>
            <div>
              <div className="main-text">{t("transport.fromTitle")}</div>
              <div className="secondary-text">{t("transport.fromSubtitle")}</div>
            </div>
          </components.Placeholder>
        )}
      </>
    );
  };

  const PlaceholderToInput = (props: any) => {
    return (
      <>
        {(props?.value && !disableCustomRouteAsync) ||
        (onSecondInputClick && props.isFocused && !disableCustomRouteAsync) ? null : (
          <components.Placeholder {...props}>
            <div className="main-text">{t("transport.toTitle")}</div>
            <div className="secondary-text">{t("transport.toSubtitle")}</div>
          </components.Placeholder>
        )}
      </>
    );
  };

  const ClearIndicatorFirstInput = (props: any) => {
    const { clearValue, setOnFirstInputClick, hasValue } = props;

    const handleClearInput = (e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      if (hasValue) {
        setTimeout(() => {
          clearValue(); // Clear the value after a small delay
          setSelectedFromStation(null);
        }, 0);
        setOnFirstInputClick(false); // Reset click state
      }
    };

    return (
      <div onMouseDown={handleClearInput} onTouchStart={handleClearInput}>
        <div className="remove-x">X</div>
      </div>
    );
  };

  const ClearIndicatorSecondInput = (props: any) => {
    const { clearValue, setOnSecondInputClick, hasValue } = props;

    const handleClearInput = (e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      if (hasValue) {
        setTimeout(() => {
          clearValue(); // Clear the value after a small delay
          setSelectedToStation(null);
        }, 0);
        setOnSecondInputClick(false); // Reset click state
      }
    };

    return (
      <div onMouseDown={handleClearInput} onTouchStart={handleClearInput}>
        <div className="remove-x">X</div>
      </div>
    );
  };

  const MenuList = (props: any) => {
    return (
      <div
        className="menu-list"
        style={{
          width: containerWidth,
          left: "-" + borderWidth,
          zIndex: 1000,
          position: "relative",
          opacity: 1,
          display: props?.selectProps?.inputValue ? "block" : "none"
        }}
      >
        {props?.selectProps?.inputValue !== "" ? (
          props.children
        ) : (
          <div
            style={{
              boxSizing: "border-box",
              color: "hsl(0, 0%, 60%)",
              padding: "8px 12px",
              textAlign: "left",
              zIndex: 1000
            }}
          >
            Type to search...
          </div>
        )}
      </div>
    );
  };

  const Options = (props: any) => {
    const lengthOfInputValue = props?.selectProps.inputValue?.replace(/\s/g, "").split("")?.length;
    const inputValueSplitOnWordsArr = props?.selectProps?.inputValue.split(" ");
    return (
      <components.Option {...props} className="option">
        <div className="main-text">
          {props?.data?.name.split(" ").map((word: string, index: number) => {
            return (
              <span key={index}>
                {index > 0 && <span> </span>}
                {word.split("").map((letter, letterIndex) =>
                  (letterIndex <
                    inputValueSplitOnWordsArr.find(
                      (inputValueWord: string) => checkIfStartsWithGivenString(word, inputValueWord) === true
                    )?.length &&
                    inputValueSplitOnWordsArr.find(
                      (inputValueWord: string) => checkIfStartsWithGivenString(word, inputValueWord) === true
                    )) ||
                  (letterIndex <
                    lengthOfInputValue -
                      (index > 0 ? getLengthOfStringBeforeGivenWordWithoutSpaces(props?.data?.name, index) : 0) &&
                    checkIfStartsWithGivenString(props?.data?.name, props?.selectProps?.inputValue)) ||
                  (letterIndex < word?.split("").length &&
                    checkIfStartsWithGivenString(props?.selectProps?.inputValue, props?.data?.name)) ? (
                    <span key={letterIndex} className="main-text--bold">
                      {letter}
                    </span>
                  ) : (
                    <span key={letterIndex}>{letter}</span>
                  )
                )}
              </span>
            );
          })}
        </div>
      </components.Option>
    );
  };

  return (
    <div>
      <div className="row__label row__label-align">{t("transport.route")}</div>
      <div
        ref={elementRef}
        className={`custom-google-places custom-google-places${disableCustomRouteAsync ? "--disabled" : ""}`}
      >
        <div className="custom-google-places__input-box" style={{ zIndex: 2 }}>
          <img className="custom-google-places__icon" src={fromIcon?.default} alt="from" />
          <AsyncSelect
            value={selectedFromStation}
            loadingMessage={(event: any) => (event?.inputValue?.length > 2 ? "Loading..." : "Type to search")}
            noOptionsMessage={noOptionMessages}
            loadOptions={loadOptions}
            onChange={(option: any) => {
              setSelectedFromStation(option); // Ensure state is updated properly
              setOnFirstInputClick(false); // Reset click state when value changes
            }}
            onMenuOpen={() => {
              setDisableCustomRouteAsync(false);
              setOnSecondInputClick(false);
              setOnFirstInputClick(true);
              // SET ACCURATE VALUES IN CASE WIDTH OF SCREEN IS CHANGED OR RESIZED
              // @ts-ignore
              setBorderWidth(getComputedStyle(elementRef.current)?.borderInlineWidth);
              // @ts-ignore
              setContainerWidth(getComputedStyle(elementRef.current)?.width);
            }}
            onBlur={() => setOnFirstInputClick(false)}
            onFocus={() => onFocusCustomRouteAsync()}
            isSearchable={selectedFromStation?.value ? false : true}
            isClearable={true}
            openMenuOnClick={selectedFromStation?.value ? false : true}
            classNamePrefix={"react-select"}
            styles={{
              menu: (defaultStyles: any) => ({
                ...defaultStyles
              }),
              indicatorSeparator: (defaultStyles: any) => ({
                ...defaultStyles,
                display: "none" // hide indicator separator
              }),
              dropdownIndicator: (defaultStyles: any) => ({
                ...defaultStyles,
                display: "none" // hide dropdown container
              }),
              popupIndicator: (defaultStyles: any) => ({
                ...defaultStyles,
                width: "20px !important",
                height: "20px !important"
              }),
              clearIndicator: (defaultStyles: any) => ({
                ...defaultStyles,
                color: theme.palette.primary.light,
                marginRight: 4,
                padding: "8px",
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.primary.light,
                  backgroundColor: "#ececeb",
                  borderRadius: "50%"
                }
              }),
              placeholder: (base: any) => ({
                ...base,
                margin: 0,
                padding: 0
              }),
              container: (base: any) => ({
                ...base,
                flex: 1
              }),
              singleValue: (base: any) => ({
                ...base,
                margin: 0,
                minHeight: "30px",
                display: "flex",
                alignItems: "center"
              }),
              control: (base: any) => ({
                ...base,
                paddingLeft: "30px",
                flex: 1,
                backgroundColor: error ? `${theme.palette.redOpacity01.main} !important` : "transparent",
                border: "none",
                outline: "none",
                width: error ? "100%" : "100%",
                borderRadius: 0,
                boxShadow: "none",
                cursor: "text",
                "&:hover": {
                  border: "none"
                }
              }),
              option: (styles: any, { isFocused }: any) => {
                return {
                  ...styles,
                  minHeight: "48px",
                  lineHeight: "48px",
                  backgroundColor: isFocused ? "#f2f2f2" : "transparent",
                  color: "#000000",
                  "&:active": {
                    background: `#f2f2f2 !important`
                  }
                };
              },
              input: () => ({
                fontFamily: `Neutraface2Book`,
                minHeight: "38px",
                lineHeight: "38px",
                "& input": {
                  font: "inherit"
                }
              }),
              noOptionsMessage: (base: any) => ({
                ...base,
                textAlign: "left"
              }),

              loadingMessage: (base: any) => ({
                ...base,
                textAlign: "left"
              })
            }}
            components={{
              Placeholder: PlaceholderFromInput,
              SingleValue: SingleValueInputFirst,
              MenuList: MenuList,
              Option: Options,
              ClearIndicator: (props: any) => (
                <ClearIndicatorFirstInput
                  {...props}
                  setOnFirstInputClick={setOnFirstInputClick}
                  hasValue={!!selectedFromStation} // Pass whether there is a value to clear
                />
              )
            }}
            inputId={firstInputId}
          />
        </div>

        <div className="custom-google-places__divider">
          <span></span>
        </div>
        <div className="custom-google-places__input-box" style={{ zIndex: 1 }}>
          <img className="custom-google-places__icon " src={toIcon?.default} alt="to" />
          <AsyncSelect
            value={selectedToStation}
            loadingMessage={(event: any) => (event?.inputValue?.length > 2 ? "Loading..." : "Type to search")}
            noOptionsMessage={noOptionMessages}
            loadOptions={loadOptions}
            onChange={(option: any) => {
              setSelectedToStation(option); // Ensure state is updated properly
              setOnSecondInputClick(false); // Reset click state when value changes
            }}
            onMenuOpen={() => {
              setDisableCustomRouteAsync(false);
              setOnSecondInputClick(true);
              setOnFirstInputClick(false);
              // SET ACCURATE VALUES IN CASE WIDTH OF SCREEN IS CHANGED OR RESIZED
              // @ts-ignore
              setBorderWidth(getComputedStyle(elementRef.current)?.borderInlineWidth);
              // @ts-ignore
              setContainerWidth(getComputedStyle(elementRef.current)?.width);
            }}
            onBlur={() => {
              setOnSecondInputClick(false);
            }}
            onFocus={() => {
              onFocusCustomRouteAsync();
            }}
            isSearchable={selectedToStation?.value ? false : true}
            isClearable={true}
            openMenuOnClick={selectedToStation?.value ? false : true}
            classNamePrefix={"react-select"}
            styles={{
              menu: (defaultStyles: any) => ({
                ...defaultStyles
              }),
              indicatorSeparator: (defaultStyles: any) => ({
                ...defaultStyles,
                display: "none" // hide indicator separator
              }),
              dropdownIndicator: (defaultStyles: any) => ({
                ...defaultStyles,
                display: "none" // hide dropdown container
              }),
              popupIndicator: (defaultStyles: any) => ({
                ...defaultStyles,
                width: "20px !important",
                height: "20px !important"
              }),
              clearIndicator: (defaultStyles: any) => ({
                ...defaultStyles,
                color: theme.palette.primary.light,
                marginRight: 4,
                padding: "8px",
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.primary.light,
                  backgroundColor: "#ececeb",
                  borderRadius: "50%"
                }
              }),
              placeholder: (base: any) => ({
                ...base,
                margin: 0,
                padding: 0
              }),
              container: (base: any) => ({
                ...base,
                flex: 1
              }),
              singleValue: (base: any) => ({
                ...base,
                margin: 0,
                minHeight: "30px",
                display: "flex",
                alignItems: "center"
              }),
              control: (base: any) => ({
                ...base,
                padding: 0,
                paddingLeft: "30px",
                flex: 1,
                backgroundColor: error ? `${theme.palette.redOpacity01.main} !important` : "transparent",
                border: "none",
                outline: "none",
                width: error ? "100%" : "100%",
                borderRadius: 0,
                boxShadow: "none",
                cursor: "text",
                "&:hover": {
                  border: "none"
                }
              }),
              option: (styles: any, { isFocused }: any) => {
                return {
                  ...styles,
                  minHeight: "48px",
                  lineHeight: "48px",
                  backgroundColor: isFocused ? "#f2f2f2" : "transparent",
                  color: "#000000",
                  "&:active": {
                    background: "#f2f2f2 !important"
                  }
                };
              },
              input: () => ({
                fontFamily: `Neutraface2Book`,
                minHeight: "38px",
                lineHeight: "38px",
                "& input": {
                  font: "inherit"
                }
              }),
              noOptionsMessage: (base: any) => ({
                ...base,
                textAlign: "left"
              }),

              loadingMessage: (base: any) => ({
                ...base,
                textAlign: "left"
              })
            }}
            components={{
              Placeholder: PlaceholderToInput,
              SingleValue: SingleValueInputSecond,
              MenuList: MenuList,
              Option: Options,
              ClearIndicator: (props: any) => (
                <ClearIndicatorSecondInput
                  {...props}
                  setOnSecondInputClick={setOnSecondInputClick}
                  hasValue={!!selectedToStation} // Pass whether there is a value to clear
                />
              )
            }}
            inputId={secondInputId}
          />
        </div>
      </div>
    </div>
  );
};

export default AppCustomRouteAsync;
