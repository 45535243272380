import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function CustomFormDropdown(props: any) {
  return (
    <Box>
      <div
        className={`form-group ${
          props.uiSchema["ui:options"].props.layout === "vertical" ? "form-group--vertical" : ""
        }`}
      >
        {props.schema.title && (
          <label
            className={`form-group__label ${
              props.uiSchema["ui:options"].props.labelColor === "white" ? "form-group__label--white" : ""
            }`}
          >
            {props.schema.title} {props.required && props?.uiSchema["ui:options"]?.props?.showStar && "*"}
            {props.schema.description && <span className="form-group__sub-label">{props.schema.description}</span>}
          </label>
        )}
        <Select
          className={`form-group__select ${props?.uiSchema["ui:options"]?.props?.fullWidth ? "full-width" : ""}`}
          value={props.formData}
          onChange={(event: any) => props.onChange(event.target.value)}
          inputProps={props.id}
          data-cy={`select-dropdown-${props.schema.title}`}
        >
          {props.schema.enum.map((option: any) => (
            <MenuItem key={option} value={option} data-cy={`dropdown-value-${props.schema.title}-${option}`}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Box>
  );
}
