import { getUnixTime } from "date-fns";
import ApiHelper from "../../../common/utils/apiHelper";
// actions
import { globalActionCreators } from "../../../common/store/modules/globalModule";

export const offerDetailsActionTypes = {
  SET_OFFER: "SET_OFFER",
  SET_TIMESLOTS: "SET_TIMESLOTS",
  TOGGLE_TIMESLOTS_LOADER: "TOGGLE_TIMESLOTS_LOADER"
};

interface IActions {
  SET_OFFER: string;
}

interface IOfferDetails {
  type: IActions["SET_OFFER"];
  offer?: any;
  offerSchema?: any;
}

type DispatchOfferDetails = (arg: IOfferDetails) => IOfferDetails;

export const initialState = {
  offer: [],
  timeslots: [],
  isLoadingTimeslots: false
};

const offerDetailsModule = (state = initialState, action: any) => {
  switch (action.type) {
    case offerDetailsActionTypes.SET_OFFER:
      return {
        ...state,
        offer: action.offer,
        offerSchema: action.offerSchema
      };
    case offerDetailsActionTypes.SET_TIMESLOTS:
      return {
        ...state,
        timeslots: action.timeslots
      };
    case offerDetailsActionTypes.TOGGLE_TIMESLOTS_LOADER:
      return {
        ...state,
        isLoadingTimeslots: action.isLoadingTimeslots
      };

    default:
      return state;
  }
};

export default offerDetailsModule;

export const offerDetailsActionCreators = {
  getOfferDetailsAction: (urlPath: string, navigate: any) => async (dispatch: DispatchOfferDetails, getState: any) => {
    const apiUrl = getState().global.apiUrl;
    const language = localStorage.getItem("i18nextLng");

    // turn on the loader
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));

    const substrOfferCode: string | undefined = urlPath.split("/").pop();

    const promises = [];
    const offerResponse = ApiHelper.get(`${apiUrl}${urlPath}`);
    const schemaResponse = ApiHelper.get(`${apiUrl}/schema/offers/${substrOfferCode}/availability`);
    promises.push(offerResponse || {});
    promises.push(schemaResponse || {});

    try {
      const [offerData, schemaData] = await Promise.all(promises);

      dispatch({
        type: offerDetailsActionTypes.SET_OFFER,
        offer: offerData.data,
        offerSchema: schemaData.data
      });
    } catch (err: any) {
      // when comes directly to non-existing offer --- navigate to NotFound page
      navigate(`${language}/*`, {
        state: {
          errorStatus: err.response.status
        }
      });
    } finally {
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
    }
  },
  getTimeslotsAction: (offerCode: string, formData: any) => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;

    // turn on timeslots loader
    dispatch({
      type: offerDetailsActionTypes.TOGGLE_TIMESLOTS_LOADER,
      isLoadingTimeslots: true
    });

    const apiParams = {
      ...formData?.original,
      start_date: getUnixTime(new Date(formData?.original?.start_date)),
      offer_code: offerCode
    };

    try {
      const response = await ApiHelper.post(`${apiUrl}/offers/${offerCode}/timeslots`, {}, apiParams);
      dispatch({
        type: offerDetailsActionTypes.SET_TIMESLOTS,
        timeslots: response?.data[0]?.slots
      });
    } catch (err: any) {
    } finally {
      // turn off timeslots loader
      dispatch({
        type: offerDetailsActionTypes.TOGGLE_TIMESLOTS_LOADER,
        isLoadingTimeslots: false
      });
    }
  },
  resetOfferDetailsAction: () => (dispatch: any) => {
    dispatch({
      type: offerDetailsActionTypes.SET_OFFER,
      offer: []
    });
  }
};
