import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
// mui icons
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// helpers
import { openLinkInNewTab } from "../../common/utils/helpers/openLinkInNewTab";
import { getMenuLinkIcons } from "../../common/utils/helpers/getMenuLinkIcons";

interface AppHamburgerMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
  language?: string;
  menuLinks?: Array<any>;
}

export default function AppHamburgerMenu({ isOpen, toggleMenu, language, menuLinks }: AppHamburgerMenuProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className={`hamburger-menu ${isOpen ? "open" : ""}`}>
      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}

      <Box className="menu-items">
        <Box
          className={`menu-items__item ${isOpen ? "menu-items__item--padding" : ""}`}
          onClick={() => {
            toggleMenu();
            navigate(`/`, { replace: true });
          }}
        >
          <HomeOutlinedIcon />
          {t("hamburgerMenu.home")}
        </Box>
        {menuLinks &&
          menuLinks?.length > 0 &&
          menuLinks?.map(menu => (
            <Box
              key={menu?.link}
              className={`menu-items__item ${isOpen ? "menu-items__item--padding" : ""}`}
              onClick={() => {
                toggleMenu();
                openLinkInNewTab(menu?.link);
              }}
            >
              {getMenuLinkIcons(menu?.icon)}
              {menu?.label}
            </Box>
          ))}
      </Box>
    </Box>
  );
}
