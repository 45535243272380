// TBD: UNIFY NAMING OF ICONS/IMAGES BELOW AND NAMING OF SVG FILES
// ICONS SHOULD BE SEPARATED WITH "_"

// no img
export const noImgPlaceholder = require("./no-img-placeholder.jpg");
// arrow
export const arrowUp = require("./arrow_up.svg");
export const arrowUpLight = require("./arrow_up_light.svg");
export const arrowRight = require("./arrow_right_dark.svg");
export const arrowUp2 = require("./arrow_up2.svg");
export const arrowDown2 = require("./arrow_down2.svg");
// favorite heart
export const favoriteActiveIcon = require("./favorite_active.svg");
export const favoriteInactiveIcon = require("./favorite_inactive.svg");
// favorite star
export const favoriteStarActiveIcon = require("./favorite_star_active.svg");
// language
export const languageIcon = require("./language.svg");
// logo
export const logoIconDefault = require("./get-local-logo.svg");
/* TO DO: Adjust this part after BE changes */
export const getLocalLogo = require("./get-local.svg");
// icons
export const exploreIcon = require("./explore.svg");
export const errorIcon = require("./error-icon.svg");
export const greenCheckmarkIcon = require("./green-checkmark.svg");
export const requestedYellowIcon = require("./requested-icon.svg");
export const requestedPngYellowIcon = require("./requested-png-icon.png");
// offer details price icons
export const adultPriceIcon = require("./price_full_icon.svg");
export const extraPriceIcon = require("./price_extra_icon.svg");
export const childPriceIcon = require("./price_child_icon.svg");
export const infantPriceIcon = require("./price_infant_icon.svg");
// hour icon
export const hourIcon = require("./hour_icon.svg");
export const additionalHourIcon = require("./additional_hour_icon.svg");
// day icon
export const dayIcon = require("./day_icon.svg");
export const additionalDayIcon = require("./additional_day_icon.svg");
// piece icon
export const pieceIcon = require("./piece_icon.svg");
export const additionalPieceIcon = require("./additional_piece_icon.svg");
// duration icon
export const durationIcon = require("./duration_icon.svg");
// map
export const mapMarkerIcon = require("./marker_icon.svg");
export const mapMarkerRedIcon = require("./marker_red_icon.svg");
// dropzone
export const alertExclMarkIcon = require("./alert-excl-mark.svg");
export const checkGreyIcon = require("./checkmark-icon-dark.svg");
export const closeGreyWider = require("./close-grey-wider.svg");
export const infoCircleLightGrayIcon = require("./circle-info-light-gray.svg");
// export launch
export const exportLaunchIcon = require("./export-launch.svg");
// close
export const closeLightIcon = require("./close_light.svg");
export const closeDarkIcon = require("./close_dark.svg");
// transport
export const sbbTransportOption = require("./sbb-transport-option.jpg");
export const serviceClassIcon = require("./service-class-icon.svg");
export const directionIcon = require("./direction-icon.svg");
export const fromIcon = require("./from-icon.svg");
export const toIcon = require("./to-icon.svg");
export const infoIcon = require("./infobutton.svg");
export const halfFareIcon = require("./half_fare_icon.svg");
export const dateIcon = require("./date_icon.svg");
export const downArrowIcon = require("./down-arrow.svg");
export const timeIcon = require("./time_icon.svg");
export const plusIcon = require("./plus.svg");
