import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
import { useCookies } from "react-cookie";
// components
import AppButton from "../ui/AppButton";

export default function AppAcceptCookiesBox() {
  const [cookies, setCookie] = useCookies(["cookieAccepted"]);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleUnderstandButton = () => {
    // GA4 will be initialized on AppLayout component (after changing "cookieAccepted" cookie)
    setCookie("cookieAccepted", true, { path: "/" });
  };

  const handleDenyButton = () => {
    setCookie("cookieAccepted", false, { path: "/" });
  };

  return (
    <Box className="accept-box">
      <Box className="accept-box__content" pt={2.5} pb={2.5} pl={5} pr={5}>
        <Box className="accept-box__content__title" mb={2}>
          {t("cookies.title")}
        </Box>
        <Box className="accept-box__content__subtitle" mb={3}>
          {t("cookies.subtitle")}
        </Box>
        <Grid container sx={{ flexDirection: "row" }} spacing={2}>
          <Grid item xs={6} sm={3} md={2}>
            <AppButton
              label={t("cookies.acceptButton")}
              onClick={handleUnderstandButton}
              variant="contained"
              disableRipple={true}
              customBackgroundColor={theme.palette.success.main}
              customHoverColor={theme.palette.success.main}
              fontSize="14px"
              dataAttribute="accept-cookies-btn"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <AppButton
              label={t("cookies.denyButton")}
              onClick={handleDenyButton}
              variant="contained"
              disableRipple={true}
              customBackgroundColor={theme.palette.error.main}
              customHoverColor={theme.palette.error.main}
              fontSize="14px"
              dataAttribute="deny-cookies-btn"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
